/**
 * Legacy Datatap Paths
 * ONLY React router And Python's server paths are included here
 */

export const paths = {
    administration: '/administration/',
    authorizationHistory: '/authorization/:authorizationId/history/',
    authorizationsAdd: '/core/authorization/add/',
    authorizationsList: '/authorizations/list/',
    connectorEngine: '/connector-engine/',
    connectorEngineConnector: '/connector-engine/:connectorSlug/',
    connectorEngineConnectorConfig: '/connector-engine/:connectorSlug/config/',
    connectorEngineConnectorWorkflow: '/connector-engine/:connectorSlug/workflow/:workflow/',
    connectorEngineHelp: '/connector-engine/help/',
    dataQuality: '/data-quality/',
    dataQualityDuplicateIssue: '/data-quality/duplication/issues/:issueId/',
    dataQualityDuplication: '/data-quality/duplication/',
    dataQualityVolumeIssue: '/data-quality/volume/issues/:issueId/',
    dataQualityVolume: '/data-quality/volume/',
    dataQualityEditMonitor: '/data-quality/monitors/:monitorUuid/',
    dataQualityUniversalMonitorRowDuplicates: '/data-quality/universal-monitors/duplicates/',
    dataQualityUniversalMonitorVolumes: '/data-quality/universal-monitors/volumes/',
    dataQualityValidity: '/data-quality/validity/',
    dataQualityValidityIssue: '/data-quality/validity/issues/:issueId/',
    datastreamDetails: '/datastreams/:datastreamId/details/:id/',
    datastreamExtractColumn: '/datastreams/:datastreamId/extracts/:extractId/column/',
    datastreamExtractEnrichment: '/datastreams/:datastreamId/extracts/:extractId/enrich/:enrichmentId/',
    datastreamExtractPreview: '/datastreams/:datastreamId/extracts/:extractId/',
    datastreamExtractsList: '/datastreams/:datastreamId/extracts/',
    datastreamExtractDuplicateIssue: '/datastreams/:datastreamId/extracts/:extractId/issues/row-duplicates/:issueId/',
    datastreamExtractValueIssue: '/datastreams/:datastreamId/extracts/:extractId/issues/values/:issueId/',
    datastreamExtractVolumeIssue: '/datastreams/:datastreamId/extracts/:extractId/issues/volumes/:issueId/',
    datastreamHistory: '/datastreams/:datastreamId/history/',
    datastreamHistoryRevision: '/datastreams/:datastreamId/history/:revisionId/',
    datastreamIssues: '/datastreams/:datastreamId/issues/',
    datastreamMapping: '/datastreams/:datastreamId/mapping/',
    datastreamOverview: '/datastreams/:datastreamId/',
    datastreamRetention: '/datastreams/:datastreamId/retention/',
    datastreams: '/datastreams/',
    datastreamsAdd: '/core/apidatastream/add/',
    datastreamSettings: '/datastreams/:datastreamId/settings/',
    datastreamSetupDestinations: '/datastream_setup/:datastreamId/destinations/',
    datastreamsList: '/datastreams/list/',
    datastreamWorkflow: '/datastreams/:datastreamId/workflow/',
    destination: '/target/basetarget/add/',
    destinationsAdd: '/target/basetarget/add/',
    downloadJobArtifact: '/api/web-app/artifact/:artifactId/download/',
    enrichmentsImport: '/enrichments/enrichment/import/',
    exploreUpsell: '/feature/explore/',
    extractionLogsList: '/extraction-logs/list/',
    extractsList: '/extracts/list/',
    home: '/home/',
    login: '/login/',
    redirected: '/redirected/',
    mappingTables: '/mapping/mappingtable/',
    mediaScreenshots: '/imgsrv/mediascreenshot/',
    oauthInvite: '/oauth2/invite/',
    oauthReauthorize: '/oauth2/reauthorize/:id/',
    oauthReauthorizeEmailForm: '/oauth2/reauthorize/:id/email-form/',
    performanceManager: '/performance-manager/',
    presentUpsell: '/feature/present/',
    profile: '/accounts/profile/',
    targetColumns: '/target/targetcolumns/',
    transferList: '/target/list/',
    usageManager: '/usage-manager/',
    usageManagerUnsubscribe: '/usage-manager/unsubscribe/',
    valueTables: '/mapping/valuetable/',
    workspace: '/activity/:stackSlug/',
    workspaceDetails: '/activity/:stackSlug/details/',
    workspaceJobDetails: '/activity/:stackSlug/details/:jobId/',
    workspaceMappings: '/adverity_workspace/adverityworkspacemappingtable/',
    workspaceMappingsDetails: '/adverity_workspace/adverityworkspacemappingtable/mappings/:mappingTableId/',
} as const
