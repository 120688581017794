import { forwardRef } from 'react'

import { getFocusRing, useFocusStyle } from '../../utils/focus'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

const commonBehaviourStyles = {
    focus: {
        textDecoration: 'none',
    },
    disabled: {
        color: 'textMuted',
        cursor: 'not-allowed',
    },
} as const

const defaultBehaviourStyles = {
    ...commonBehaviourStyles,
    focus: {
        ...commonBehaviourStyles.focus,
        color: 'brand',
    },
    active: {
        color: 'brandActive',
    },
    hover: {
        color: 'brandHighlighted',
    },
} as const

const invertedBehaviourStyles = {
    ...commonBehaviourStyles,
    focus: {
        ...commonBehaviourStyles.focus,
        color: 'textInvertedSecondary',
    },
    active: {
        color: 'brandSecondaryActive',
    },
    hover: {
        color: 'brandSecondaryHighlighted',
    },
} as const

type Props = {
    inverted?: boolean
    target?: string
    href?: string
    disabled?: boolean
    textDecoration?: 'underline' | 'none'
} & BoxProps

export const Link = forwardRef<HTMLElement, Props>(
    ({ disabled, textDecoration = 'underline', css, inverted = false, ...props }, ref) => {
        const behaviourStyles = inverted ? invertedBehaviourStyles : defaultBehaviourStyles

        return (
            <Box
                ref={ref}
                as="a"
                display="inline-block"
                color={inverted ? 'textInvertedSecondary' : 'brand'}
                tabIndex={disabled ? -1 : undefined}
                css={[
                    {
                        cursor: 'pointer',
                        textDecoration,
                        '&:active': behaviourStyles.active,
                        '&:focus': behaviourStyles.focus,
                        '&:hover': behaviourStyles.hover,
                        ...getFocusRing(useFocusStyle(inverted ? 'inverted' : 'brand', true, 0.25)),
                        '&:disabled, &[aria-disabled=true]': behaviourStyles.disabled,
                    },
                    css,
                ]}
                {...(props.target === undefined || props.target === '_self' ? {} : { rel: 'noopener noreferrer' })}
                {...props}
            />
        )
    },
)

Link.displayName = 'Link'
