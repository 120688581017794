import { forwardRef } from 'react'

import { getFocusRing, useFocusStyle } from '../../utils/focus'
import { isVisualRegressionEnv } from '../../utils/is-visual-regression-env'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'
import { ConditionalWrapWithTooltip } from '../tooltip-internal/conditional-wrap-with-tooltip'

type Props = BoxProps & {
    tooltip?: string
}

export const Image = forwardRef<HTMLElement, Props>(({ tooltip, ...restProps }, ref) => (
    <ConditionalWrapWithTooltip tooltip={tooltip} {...(isVisualRegressionEnv && { visible: true })}>
        <Box
            ref={ref}
            tabIndex={tooltip ? 0 : undefined}
            css={{
                ...getFocusRing(useFocusStyle()),
            }}
            as="img"
            maxWidth="100%"
            {...restProps}
        />
    </ConditionalWrapWithTooltip>
))

Image.displayName = 'Image'
