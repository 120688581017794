import { paths } from '@adverity/legacy-datatap-paths/paths'

/**
 * Matches a given path against a pattern.
 * @param path
 * @param pattern
 * @returns boolean
 */
const matchPath = (path: string, pattern: string): boolean => {
    const cleanPath = path.split('?')[0] as string
    const pathSegments = cleanPath.split('/').filter(Boolean)
    const patternSegments = pattern.split('/').filter(Boolean)

    if (pathSegments.length !== patternSegments.length) {
        return false
    }

    return patternSegments.every((segment, index) => segment.startsWith(':') || segment === pathSegments[index])
}

/**
 * Helper function - Checks if the path exist in the legacy-datatap paths
 * @param string
 * @returns boolean
 */
export const isLegacyDatatapPath = (path: string) => Object.values(paths).some((route) => matchPath(path, route))
