import { removePxUnit } from './css-unit'
import { useTheme } from './use-theme'

export const useOffset = () => {
    const theme = useTheme()

    // The `px` unit needs to be removed, because Popper expects a number here.
    // If the value contains a CSS unit, the popover won't be positioned properly.
    return removePxUnit(theme.space['x-small'] as string)
}

export const useBaseOffsetModifier = () => ({
    name: 'offset',
    options: {
        offset: [0, useOffset()],
    },
})
