/**
 * Helper function - getCompatibleTo
 * Compatibility helper between Atrium and Legacy
 * @returns string
 */
export const getCompatibleTo = ({
    from,
    params,
    pathname,
    search,
    searchParams,
    to,
}: {
    from?: string
    params?: Record<string, unknown>
    pathname: string
    search?: boolean | Record<string, string | number | Array<string | number>>
    searchParams: URLSearchParams
    to: string
}) => {
    let mutableTo = to

    if (from?.includes('$')) {
        const pathnameSplit = pathname.split('/')
        const variableIndexInFrom = new Set(
            from
                .split('/')
                .map((item, index) => (item.startsWith('$') ? index : -1))
                .filter((index) => index !== -1),
        )

        const toSplit = to.split('/')

        mutableTo = toSplit
            .map((item, index) => {
                if (item.startsWith('$') && variableIndexInFrom.has(index)) {
                    return pathnameSplit[index]
                }

                return item
            })
            .join('/')
    }

    if (to.includes('$') && params) {
        Object.entries(params).forEach(([key, value]) => {
            mutableTo = mutableTo.replace(`$${key}`, String(value))
        })
    }

    if (!mutableTo.endsWith('/')) {
        mutableTo += '/'
    }

    if (search) {
        if (typeof search === 'boolean') {
            return `${mutableTo}?${searchParams.toString()}`
        }

        const newSearch = new URLSearchParams()

        Object.entries(search).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((param) => newSearch.append(key, String(param)))
            } else {
                newSearch.set(key, String(value))
            }
        })

        return `${mutableTo}?${newSearch.toString()}`
    }

    return mutableTo
}
