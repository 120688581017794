/**
 * Helper function - Object from URLSearchParams
 * @param searchParams
 * @returns object
 */
export const getSearchObject = (searchParams: URLSearchParams) => {
    const mutableSearchObject: Record<string, string | Array<string>> = {}
    const entries: Array<[string, string]> = [...searchParams.entries()]

    entries.forEach(([key, value]) => {
        if (mutableSearchObject[key]) {
            mutableSearchObject[key] = Array.isArray(mutableSearchObject[key])
                ? [...mutableSearchObject[key], value]
                : [mutableSearchObject[key], value]
        } else {
            mutableSearchObject[key] = value
        }
    })

    return mutableSearchObject
}
