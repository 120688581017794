import type { ReactNode } from 'react'
import { createContext, useContext, useLayoutEffect, useRef, useState } from 'react'

import * as ReactDOM from 'react-dom'

// We cannot set body here, as it's possible that it will be not created at the time of running this line
const PortalContext = createContext<HTMLElement | null>(null)

type Props = {
    children: ReactNode
    context?: HTMLElement
}

export const Portal = ({ children, context }: Props) => {
    // eslint-disable-next-line react/hook-use-state
    const [propsMountNode] = useState(context)
    const [mounted, setMounted] = useState(false)
    // if it's a nested portal, context is the parent portal
    // otherwise it's document.body
    // https://github.com/reakit/reakit/issues/513
    const contextMountNode = useContext(PortalContext)
    // mount node provided through props should take precedence
    const mountNode = propsMountNode ?? contextMountNode
    const hostNodeRef = useRef<HTMLDivElement | null>(null)

    useLayoutEffect(() => {
        if (!hostNodeRef.current) {
            const mutableElement = document.createElement('div')

            mutableElement.className = '__reakit-portal'

            hostNodeRef.current = mutableElement
            setMounted(true)
        }

        const clientMountNode = mountNode ?? document.body

        clientMountNode.append(hostNodeRef.current)

        return () => {
            const oldNode = hostNodeRef.current

            hostNodeRef.current = null
            // Lose race condition with `useRestoreFocus`
            setTimeout(() => {
                oldNode?.remove()
            })
        }
    }, [mountNode])

    return mounted
        ? ReactDOM.createPortal(
              <PortalContext.Provider value={hostNodeRef.current}>{children}</PortalContext.Provider>,
              // hostNodeRef's current depends on "mounted", so in this case it's non-null
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              hostNodeRef.current!,
          )
        : null
}

Portal.displayName = 'Portal'
